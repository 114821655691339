import React, { useEffect, useState } from "react"

export default () => {
  const [data, setData] = useState([])

  const fetchData = () => {
    fetch("https://jsonplaceholder.typicode.com/users/")
      .then(res => res.json())
      .then(result => setData(result))
      .catch(err => console.log("error"))
  }

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <div>
      <h2>juntaspodemos.manuela.org.pe</h2>
      <p>Traer data de un api: https://jsonplaceholder.typicode.com/users</p>
      {data.map((item, i) => (
        <>
          <div>id: {item.id}</div>
          <div>name: {item.name}</div>
          <div>email: {item.email}</div>
          <br />
        </>
      ))}
    </div>
  )
}
